<template>
  <div id="microbeInspectionReport">
    <el-dialog
      :title="microbeInspectionReportFormTitle"
      width="1000px"
      :visible.sync="microbeInspectionReportDialogVisible"
      :close-on-click-modal="false"
      @close="microbeInspectionReportDialogClose"
    >
    <div id="pdfDom">
      <el-form
        ref="microbeInspectionReportFormRef"
        :model="microbeInspectionReportForm"
        :rules="microbeInspectionReportFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="类型" prop="type">
              <el-radio-group
                v-model="microbeInspectionReportForm.type"
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              >
                <el-radio :label="1">
                  初检
                </el-radio>
                <el-radio :label="2">
                  复检
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="编号" prop="formNo">
              <el-input
                v-model="microbeInspectionReportForm.formNo"
                placeholder="请输入编号"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="产品名称" prop="productName">
              <el-input
                v-model="microbeInspectionReportForm.productName"
                placeholder="请输入产品名称"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="规格型号" prop="spec">
              <el-input
                v-model="microbeInspectionReportForm.spec"
                placeholder="请输入规格型号"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="生产批号" prop="batchNo">
              <el-input
                v-model="microbeInspectionReportForm.batchNo"
                placeholder="请输入生产批号"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="抽检数量" prop="inspectionQuantity">
              <el-input
                v-model="microbeInspectionReportForm.inspectionQuantity"
                placeholder="请输入抽检数量"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="检验日期" prop="inspectionDate">
              <el-date-picker
                v-model="microbeInspectionReportForm.inspectionDate"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="报告日期" prop="reportDate">
              <el-date-picker
                v-model="microbeInspectionReportForm.reportDate"
                placeholder="请选择报告日期"
                value-format="yyyy-MM-dd"
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="报告依据" prop="basis">
              <el-input
                v-model="microbeInspectionReportForm.basis"
                readonly
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider>1、细菌菌落总数检验记录</el-divider>
        <el-row>
          <el-col :span="12">
            <el-form-item label="检测标准" prop="oneStandard">
              <el-input
                v-model="microbeInspectionReportForm.oneStandard"
                readonly
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="培养基" prop="oneMedium">
              <el-input
                v-model="microbeInspectionReportForm.oneMedium"
                placeholder="营养琼脂培养基（批号：）"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="培养温度" prop="oneTemperature">
              <el-input
                v-model="microbeInspectionReportForm.oneTemperature"
                placeholder="写具体温度，能写温度范围"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="培养时间" prop="oneTime">
              <el-input
                v-model="microbeInspectionReportForm.oneTime"
                placeholder="格式（xx年x月x日X点--xx年x月x日X点）"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="平板1" prop="oneFlat1">
              <el-input
                v-model="microbeInspectionReportForm.oneFlat1"
                placeholder="请输入平板1"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="平板2" prop="oneFlat2">
              <el-input
                v-model="microbeInspectionReportForm.oneFlat2"
                placeholder="请输入平板2"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="平板3" prop="oneFlat3">
              <el-input
                v-model="microbeInspectionReportForm.oneFlat3"
                placeholder="请输入平板3"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="平板4" prop="oneFlat4">
              <el-input
                v-model="microbeInspectionReportForm.oneFlat4"
                placeholder="请输入平板4"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="平板5" prop="oneFlat5">
              <el-input
                v-model="microbeInspectionReportForm.oneFlat5"
                placeholder="请输入平板5"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="菌落总数" prop="oneColonyTotal">
              <el-input
                v-model="microbeInspectionReportForm.oneColonyTotal"
                placeholder="请输入菌落总数"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider>2、大肠杆菌检验记录</el-divider>
        <el-row>
          <el-col :span="12">
            <el-form-item label="检测标准" prop="twoStandard">
              <el-input
                v-model="microbeInspectionReportForm.twoStandard"
                readonly
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="培养基" prop="twoMedium">
              <el-input
                v-model="microbeInspectionReportForm.twoMedium"
                placeholder="营养琼脂培养基（批号：）"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="培养温度" prop="twoTemperature">
              <el-input
                v-model="microbeInspectionReportForm.twoTemperature"
                placeholder="写具体温度，能写温度范围"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="培养时间" prop="twoTime">
              <el-input
                v-model="microbeInspectionReportForm.twoTime"
                placeholder="格式（xx年x月x日X点--xx年x月x日X点）"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="检测结果" prop="twoResult">
              <el-radio-group
                v-model="microbeInspectionReportForm.twoResult"
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              >
                <el-radio :label="1">
                  产酸产气
                </el-radio>
                <el-radio :label="2">
                  不产酸不产气
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label-width="20px">
              若产酸产气，则需进行以下实验，并记录实验结果：
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="实验1:划线接种伊红美蓝琼脂平板结果（菌落形态描述）" prop="twoTest1" label-width="400px">
              <el-input
                v-model="microbeInspectionReportForm.twoTest1"
                placeholder="请输入划线接种伊红美蓝琼脂平板结果（菌落形态描述）"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="实验2：革兰氏染色镜检结果" prop="twoTest2" label-width="230px">
              <el-radio-group
                v-model="microbeInspectionReportForm.twoTest2"
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              >
                <el-radio :label="1">
                  阴性
                </el-radio>
                <el-radio :label="2">
                  阳性
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="实验3：乳糖发酵管实验结果" prop="twoTest3" label-width="230px">
              <el-radio-group
                v-model="microbeInspectionReportForm.twoTest3"
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              >
                <el-radio :label="1">
                  产酸产气
                </el-radio>
                <el-radio :label="2">
                  不产酸不产气
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="最终实验结论" prop="twoFinalResult" label-width="130px">
              <el-input
                v-model="microbeInspectionReportForm.twoFinalResult"
                placeholder="请输入最终实验结论"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider>3、绿脓杆菌检测记录</el-divider>
        <el-row>
          <el-col :span="12">
            <el-form-item label="检测标准" prop="threeStandard">
              <el-input
                v-model="microbeInspectionReportForm.threeStandard"
                readonly
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="培养基" prop="threeMedium">
              <el-input
                v-model="microbeInspectionReportForm.threeMedium"
                placeholder="SCDLP培养液（批号：）"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="培养温度" prop="threeTemperature">
              <el-input
                v-model="microbeInspectionReportForm.threeTemperature"
                placeholder="写具体温度，能写温度范围"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="培养时间" prop="threeTime">
              <el-input
                v-model="microbeInspectionReportForm.threeTime"
                placeholder="格式（xx年x月x日X点--xx年x月x日X点）"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="检测结果：培养液表面是否呈现一层薄菌膜" prop="threeResult1" label-width="310px">
              <el-radio-group
                v-model="microbeInspectionReportForm.threeResult1"
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              >
                <el-radio :label="1">
                  是
                </el-radio>
                <el-radio :label="0">
                  否
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="检测结果：培养液是否呈黄绿色或蓝绿色" prop="threeResult2" label-width="300px">
              <el-radio-group
                v-model="microbeInspectionReportForm.threeResult2"
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              >
                <el-radio :label="1">
                  是
                </el-radio>
                <el-radio :label="0">
                  否
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label-width="20px">
              若培养液农面呈现一层薄菌膜，培养液常呈黄绿色或蓝绿色需进行以下实验，并记录实验结果：
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="实验1：划线接种十六烷三甲基溴化胺琼脂平板" prop="threeTest1" label-width="340px">
              <el-input
                v-model="microbeInspectionReportForm.threeTest1"
                placeholder="请输入划线接种十六烷三甲基溴化胺琼脂平板"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="实验2：革兰氏染色" prop="threeTest2" label-width="172px">
              <el-radio-group
                v-model="microbeInspectionReportForm.threeTest2"
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              >
                <el-radio :label="1">
                  阴性
                </el-radio>
                <el-radio :label="2">
                  阳性
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label-width="20px">
              根据实验2结果，若为革兰氏染色阴性需进行以下实验：
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="2.1 氧化酶试验" prop="threeTest21" label-width="156px">
              <el-radio-group
                v-model="microbeInspectionReportForm.threeTest21"
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              >
                <el-radio :label="1">
                  阴性
                </el-radio>
                <el-radio :label="2">
                  阳性
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="2.2 绿脓菌素试验" prop="threeTest22" label-width="170px">
              <el-radio-group
                v-model="microbeInspectionReportForm.threeTest22"
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              >
                <el-radio :label="1">
                  阴性
                </el-radio>
                <el-radio :label="2">
                  阳性
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label-width="20px">
              根据实验2.2结果，若绿脓菌素试验结果显示阴性则进行以下实验：
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="2.2.1 硝酸盐还原产气试验" prop="threeTest221" label-width="235px">
              <el-radio-group
                v-model="microbeInspectionReportForm.threeTest221"
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              >
                <el-radio :label="1">
                  阴性
                </el-radio>
                <el-radio :label="2">
                  阳性
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="2.2.2 明胶液化试验" prop="threeTest222" label-width="190px">
              <el-radio-group
                v-model="microbeInspectionReportForm.threeTest222"
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              >
                <el-radio :label="1">
                  阴性
                </el-radio>
                <el-radio :label="2">
                  阳性
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="2.2.3 42℃生长试验" prop="threeTest223" label-width="195px">
              <el-radio-group
                v-model="microbeInspectionReportForm.threeTest223"
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              >
                <el-radio :label="1">
                  阴性
                </el-radio>
                <el-radio :label="2">
                  阳性
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="最终实验结论" prop="threeFinalResult" label-width="130px">
              <el-input
                v-model="microbeInspectionReportForm.threeFinalResult"
                placeholder="请输入最终实验结论"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider>4、金黄色葡萄球菌检验记录</el-divider>
        <el-row>
          <el-col :span="12">
            <el-form-item label="检测标准" prop="fourStandard">
              <el-input
                v-model="microbeInspectionReportForm.fourStandard"
                readonly
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="培养基" prop="fourMedium">
              <el-input
                v-model="microbeInspectionReportForm.fourMedium"
                placeholder="CDLP培养液（批号：）血琼脂培养基（批号：）"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="培养温度" prop="fourTemperature">
              <el-input
                v-model="microbeInspectionReportForm.fourTemperature"
                placeholder="写具体温度，能写温度范围"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="培养时间" prop="fourTime">
              <el-input
                v-model="microbeInspectionReportForm.fourTime"
                placeholder="格式（xx年x月x日X点--xx年x月x日X点）"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="检测结果：在血琼脂培养基上，是否有呈金黄色，大而突起，圆形，不透明，表面光滑，周围有溶血圈的菌落" prop="fourResult" label-width="715px">
              <el-radio-group
                v-model="microbeInspectionReportForm.fourResult"
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              >
                <el-radio :label="1">
                  是
                </el-radio>
                <el-radio :label="0">
                  否
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label-width="20px">
              若该菌菌落呈金黄色，大而突起，圆形，不透明，表面光滑，周围有溶血圈需进行以下实验，并记录实验结果：
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="实验1革兰氏染色镜检" prop="fourTest1" label-width="180px">
              <el-radio-group
                v-model="microbeInspectionReportForm.fourTest1"
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              >
                <el-radio :label="1">
                  阴性
                </el-radio>
                <el-radio :label="2">
                  阳性
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label-width="20px">
              根据实验1结果，若为革兰氏染色阳性需进行以下实验：
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="甘露醇发酵试验" prop="fourTest11" label-width="145px">
              <el-radio-group
                v-model="microbeInspectionReportForm.fourTest11"
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              >
                <el-radio :label="1">
                  阴性
                </el-radio>
                <el-radio :label="2">
                  阳性
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="血浆凝固酶试验" prop="fourTest12" label-width="145px">
              <el-radio-group
                v-model="microbeInspectionReportForm.fourTest12"
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              >
                <el-radio :label="1">
                  阴性
                </el-radio>
                <el-radio :label="2">
                  阳性
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="最终实验结论" prop="fourFinalResult" label-width="130px">
              <el-input
                v-model="microbeInspectionReportForm.fourFinalResult"
                placeholder="请输入最终实验结论"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider>5、溶血性链球菌检验记录</el-divider>
        <el-row>
          <el-col :span="12">
            <el-form-item label="检测标准" prop="fiveStandard">
              <el-input
                v-model="microbeInspectionReportForm.fiveStandard"
                readonly
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="培养基" prop="fiveMedium">
              <el-input
                v-model="microbeInspectionReportForm.fiveMedium"
                placeholder="葡萄糖肉汤（批号：）血琼脂平板（批号：）"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="培养温度" prop="fiveTemperature">
              <el-input
                v-model="microbeInspectionReportForm.fiveTemperature"
                placeholder="写具体温度，能写温度范围"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="培养时间" prop="fiveTime">
              <el-input
                v-model="microbeInspectionReportForm.fiveTime"
                placeholder="格式（xx年x月x日X点--xx年x月x日X点）"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="检测结果：在血琼脂培养基上，是否有灰白色，半透明或不透明，针尖状突起，表面光滑，边缘整齐，周围有无色透明溶血圈的菌落" prop="fiveResult" label-width="855px">
              <el-radio-group
                v-model="microbeInspectionReportForm.fiveResult"
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              >
                <el-radio :label="1">
                  是
                </el-radio>
                <el-radio :label="0">
                  否
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label-width="20px">
              若该菌落呈灰白色，半透明或不透明，针尖状突起，表面光滑，边缘整齐，周围有无色透明溶血圈，需进行以下实验，并记录实验结果：
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="实验1革兰氏染色镜检 " prop="fiveTest1" label-width="180px">
              <el-radio-group
                v-model="microbeInspectionReportForm.fiveTest1"
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              >
                <el-radio :label="1">
                  阴性
                </el-radio>
                <el-radio :label="2">
                  阳性
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label-width="20px">
              根据实验1结果，若为革兰氏染色阳性需进行以下实验：
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="链激酶试验" prop="fiveTest11" label-width="115px">
              <el-radio-group
                v-model="microbeInspectionReportForm.fiveTest11"
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              >
                <el-radio :label="1">
                  阴性
                </el-radio>
                <el-radio :label="2">
                  阳性
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="杆菌肤敏感试验" prop="fiveTest12" label-width="143px">
              <el-radio-group
                v-model="microbeInspectionReportForm.fiveTest12"
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              >
                <el-radio :label="1">
                  阴性
                </el-radio>
                <el-radio :label="2">
                  阳性
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="最终实验结论" prop="fiveFinalResult" label-width="130px">
              <el-input
                v-model="microbeInspectionReportForm.fiveFinalResult"
                placeholder="请输入最终实验结论"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider>6、真菌菌落总数检验记录</el-divider>
        <el-row>
          <el-col :span="12">
            <el-form-item label="检测标准" prop="sixStandard">
              <el-input
                v-model="microbeInspectionReportForm.sixStandard"
                readonly
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="培养基" prop="sixMedium">
              <el-input
                v-model="microbeInspectionReportForm.sixMedium"
                placeholder="沙氏琼脂培养基（批号：）"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="培养温度" prop="sixTemperature">
              <el-input
                v-model="microbeInspectionReportForm.sixTemperature"
                placeholder="写具体温度，能写温度范围"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="培养时间" prop="sixTime">
              <el-input
                v-model="microbeInspectionReportForm.sixTime"
                placeholder="格式（xx年x月x日X点--xx年x月x日X点）"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="平板1" prop="sixFlat1">
              <el-input
                v-model="microbeInspectionReportForm.sixFlat1"
                placeholder="请输入平板1"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="平板2" prop="sixFlat2">
              <el-input
                v-model="microbeInspectionReportForm.sixFlat2"
                placeholder="请输入平板2"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="平板3" prop="sixFlat3">
              <el-input
                v-model="microbeInspectionReportForm.sixFlat3"
                placeholder="请输入平板3"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="平板4" prop="sixFlat4">
              <el-input
                v-model="microbeInspectionReportForm.sixFlat4"
                placeholder="请输入平板4"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="平板5" prop="sixFlat5">
              <el-input
                v-model="microbeInspectionReportForm.sixFlat5"
                placeholder="请输入平板5"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="菌落总数" prop="sixColonyTotal">
              <el-input
                v-model="microbeInspectionReportForm.sixColonyTotal"
                placeholder="请输入菌落总数"
                clearable
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input
                v-model="microbeInspectionReportForm.remark"
                type="textarea"
                :rows="4"
                readonly
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="报告结论" prop="reportConclusion">
              <el-radio-group
                v-model="microbeInspectionReportForm.reportConclusion"
                :disabled="microbeInspectionReportFormTitle !== '新增微生物检验报告'
                  && microbeInspectionReportFormTitle !== '修改微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              >
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="复核结果" prop="reviewResult">
              <el-radio-group
                v-model="microbeInspectionReportForm.reviewResult"
                :disabled="microbeInspectionReportFormTitle !== '复核微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准结果" prop="approvalResult">
              <el-radio-group
                v-model="microbeInspectionReportForm.approvalResult"
                :disabled="microbeInspectionReportFormTitle !== '批准微生物检验报告'
                  && microbeInspectionReportFormTitle !== '微生物检验报告详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
      <div slot="footer">
        <el-button @click="microbeInspectionReportDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="microbeInspectionReportFormSubmit">
          确 定
        </el-button>
        <el-button
          v-if="microbeInspectionReportFormTitle === '微生物检验报告详情'"
          type="primary"
          @click="getPdf"
        >
          下 载 PDF
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="报告日期">
        <el-date-picker v-model="searchForm.reportDate" placeholder="请选择报告日期" value-format="yyyy-MM-dd" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['MICROBE_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="microbeInspectionReportPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
      highlight-current-row
    >
      <el-table-column label="类型">
        <template slot-scope="scope">
          <span v-if="scope.row.type === 1">初检</span>
          <span v-if="scope.row.type === 2">复检</span>
        </template>
      </el-table-column>
      <el-table-column prop="formNo" label="编号" />
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column prop="spec" label="规格型号" />
      <el-table-column prop="batchNo" label="生产批号" />
      <el-table-column prop="inspectionQuantity" label="抽检数量" />
      <el-table-column prop="inspectionDate" label="检验日期" />
      <el-table-column label="报告日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reportDate">{{ scope.row.reportDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="basis" label="报告依据" />
      <el-table-column label="报告结论">
        <template slot-scope="scope">
          <span v-if="scope.row.reportConclusion === 0">不合格</span>
          <span v-if="scope.row.reportConclusion === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column prop="inspector" label="检验人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.inspectionDate1">{{ scope.row.inspectionDate1.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer" label="复核人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewDate">{{ scope.row.reviewDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="复核结果">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewResult === 1">通过</span>
          <span v-if="scope.row.reviewResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column prop="approver" label="批准人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.approvalDate">{{ scope.row.approvalDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="批准结果">
        <template slot-scope="scope">
          <span v-if="scope.row.approvalResult === 1">通过</span>
          <span v-if="scope.row.approvalResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['MICROBE_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['MICROBE_UPDATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['MICROBE_REVIEW']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            复核
          </el-button>
          <el-button
            v-if="checkPermission(['MICROBE_APPROVAL']) && scope.row.status === 2"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleApproval(scope.$index, scope.row)"
          >
            批准
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="microbeInspectionReportPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addMicrobeInspectionReport,
  deleteMicrobeInspectionReport,
  updateMicrobeInspectionReport,
  selectMicrobeInspectionReportInfo,
  selectMicrobeInspectionReportList,
  reviewMicrobeInspectionReport,
  approvalMicrobeInspectionReport
} from '@/api/quality/microbeInspectionReport'
import moment from 'moment'
import html2PDF from "jspdf-html2canvas";
import { Loading } from "element-ui";

export default {
  data () {
    return {
      microbeInspectionReportDialogVisible: false,
      microbeInspectionReportFormTitle: '',
      microbeInspectionReportForm: {
        id: '',
        type: '',
        formNo: '',
        productName: '',
        spec: '',
        batchNo: '',
        inspectionQuantity: '',
        inspectionDate: '',
        reportDate: '',
        basis: '一次性使用中性电极成品检验规程',
        oneStandard: '≤100cfu/g',
        oneMedium: '',
        oneTemperature: '',
        oneTime: '',
        oneFlat1: '',
        oneFlat2: '',
        oneFlat3: '',
        oneFlat4: '',
        oneFlat5: '',
        oneColonyTotal: '',
        twoStandard: '不得检出',
        twoMedium: '',
        twoTemperature: '',
        twoTime: '',
        twoResult: '',
        twoTest1: '',
        twoTest2: '',
        twoTest3: '',
        twoFinalResult: '',
        threeStandard: '不得检出',
        threeMedium: '',
        threeTemperature: '',
        threeTime: '',
        threeResult1: '',
        threeResult2: '',
        threeTest1: '',
        threeTest2: '',
        threeTest21: '',
        threeTest22: '',
        threeTest221: '',
        threeTest222: '',
        threeTest223: '',
        threeFinalResult: '',
        fourStandard: '不得检出',
        fourMedium: '',
        fourTemperature: '',
        fourTime: '',
        fourResult: '',
        fourTest1: '',
        fourTest11: '',
        fourTest12: '',
        fourFinalResult: '',
        fiveStandard: '不得检出',
        fiveMedium: '',
        fiveTemperature: '',
        fiveTime: '',
        fiveResult: '',
        fiveTest1: '',
        fiveTest11: '',
        fiveTest12: '',
        fiveFinalResult: '',
        sixStandard: '≤100cfu/g',
        sixMedium: '',
        sixTemperature: '',
        sixTime: '',
        sixFlat1: '',
        sixFlat2: '',
        sixFlat3: '',
        sixFlat4: '',
        sixFlat5: '',
        sixColonyTotal: '',
        remark: '   细菌真菌菌落计数时，当菌落数在100以内，按实有数报告，大于100时采用二位有效数字。如果样品菌落总数超过本标准的规定，按进行复检和结果报告。\n  将留存的复检样品依前法复测2次，2次结果都达到本标准的规定，则判定被检样品合格；其中有任何1次结果超过本标准规定，则判定被检样品不合格。',
        reportConclusion: '',
        reviewResult: '',
        approvalResult: '',
        status: '',
        taskId: ''
      },
      microbeInspectionReportFormRules: {
        reportDate: [{ required: true, message: '报告日期不能为空', trigger: ['blur', 'change']}]
      },
      microbeInspectionReportPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        reportDate: ''
      }
    }
  },
  created () {
    selectMicrobeInspectionReportList(this.searchForm).then(res => {
      this.microbeInspectionReportPage = res
    })
  },
  methods: {
    microbeInspectionReportDialogClose () {
      this.$refs.microbeInspectionReportFormRef.resetFields()
    },
    microbeInspectionReportFormSubmit () {
      if (this.microbeInspectionReportFormTitle === '微生物检验报告详情') {
        this.microbeInspectionReportDialogVisible = false
        return
      }
      this.$refs.microbeInspectionReportFormRef.validate(async valid => {
        if (valid) {
          this.microbeInspectionReportForm.inspectionDate = this.microbeInspectionReportForm.inspectionDate.join('至')
          if (this.microbeInspectionReportFormTitle === '新增微生物检验报告') {
            this.microbeInspectionReportForm.id = ''
            this.microbeInspectionReportForm.status = 1
            await addMicrobeInspectionReport(this.microbeInspectionReportForm)
          } else if (this.microbeInspectionReportFormTitle === '修改微生物检验报告') {
            await updateMicrobeInspectionReport(this.microbeInspectionReportForm)
          } else if (this.microbeInspectionReportFormTitle === '复核微生物检验报告') {
            this.microbeInspectionReportForm.status = 2
            await reviewMicrobeInspectionReport(this.microbeInspectionReportForm)
          } else if (this.microbeInspectionReportFormTitle === '批准微生物检验报告') {
            this.microbeInspectionReportForm.status = 3
            await approvalMicrobeInspectionReport(this.microbeInspectionReportForm)
          }
          this.microbeInspectionReportPage = await selectMicrobeInspectionReportList(this.searchForm)
          this.microbeInspectionReportDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.microbeInspectionReportFormTitle = '新增微生物检验报告'
      this.microbeInspectionReportDialogVisible = true
      this.microbeInspectionReportForm.formNo = 'WSW' + moment().format('YYYYMMDD') + '-'
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteMicrobeInspectionReport(row.id)
        if (this.microbeInspectionReportPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.microbeInspectionReportPage = await selectMicrobeInspectionReportList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.microbeInspectionReportFormTitle = '修改微生物检验报告'
      this.microbeInspectionReportDialogVisible = true
      this.selectMicrobeInspectionReportInfoById(row.id)
    },
    handleReview (index, row) {
      this.microbeInspectionReportFormTitle = '复核微生物检验报告'
      this.microbeInspectionReportDialogVisible = true
      this.selectMicrobeInspectionReportInfoById(row.id)
    },
    handleApproval (index, row) {
      this.microbeInspectionReportFormTitle = '批准微生物检验报告'
      this.microbeInspectionReportDialogVisible = true
      this.selectMicrobeInspectionReportInfoById(row.id)
    },
    handleInfo (index, row) {
      this.microbeInspectionReportFormTitle = '微生物检验报告详情'
      this.microbeInspectionReportDialogVisible = true
      this.selectMicrobeInspectionReportInfoById(row.id)
    },
    selectMicrobeInspectionReportInfoById (id) {
      selectMicrobeInspectionReportInfo(id).then(res => {
        this.microbeInspectionReportForm.id = res.id
        this.microbeInspectionReportForm.formNo = res.formNo
        this.microbeInspectionReportForm.type = res.type
        this.microbeInspectionReportForm.productName = res.productName
        this.microbeInspectionReportForm.spec = res.spec
        this.microbeInspectionReportForm.batchNo = res.batchNo
        this.microbeInspectionReportForm.inspectionQuantity = res.inspectionQuantity
        this.microbeInspectionReportForm.inspectionDate = res.inspectionDate.split('至')
        this.microbeInspectionReportForm.reportDate = res.reportDate
        this.microbeInspectionReportForm.basis = res.basis
        this.microbeInspectionReportForm.oneStandard = res.oneStandard
        this.microbeInspectionReportForm.oneMedium = res.oneMedium
        this.microbeInspectionReportForm.oneTemperature = res.oneTemperature
        this.microbeInspectionReportForm.oneTime = res.oneTime
        this.microbeInspectionReportForm.oneFlat1 = res.oneFlat1
        this.microbeInspectionReportForm.oneFlat2 = res.oneFlat2
        this.microbeInspectionReportForm.oneFlat3 = res.oneFlat3
        this.microbeInspectionReportForm.oneFlat4 = res.oneFlat4
        this.microbeInspectionReportForm.oneFlat5 = res.oneFlat5
        this.microbeInspectionReportForm.oneColonyTotal = res.oneColonyTotal
        this.microbeInspectionReportForm.twoStandard = res.twoStandard
        this.microbeInspectionReportForm.twoMedium = res.twoMedium
        this.microbeInspectionReportForm.twoTemperature = res.twoTemperature
        this.microbeInspectionReportForm.twoTime = res.twoTime
        this.microbeInspectionReportForm.twoResult = res.twoResult
        this.microbeInspectionReportForm.twoTest1 = res.twoTest1
        this.microbeInspectionReportForm.twoTest2 = res.twoTest2
        this.microbeInspectionReportForm.twoTest3 = res.twoTest3
        this.microbeInspectionReportForm.twoFinalResult = res.twoFinalResult
        this.microbeInspectionReportForm.threeStandard = res.threeStandard
        this.microbeInspectionReportForm.threeMedium = res.threeMedium
        this.microbeInspectionReportForm.threeTemperature = res.threeTemperature
        this.microbeInspectionReportForm.threeTime = res.threeTime
        this.microbeInspectionReportForm.threeResult1 = res.threeResult1
        this.microbeInspectionReportForm.threeResult2 = res.threeResult2
        this.microbeInspectionReportForm.threeTest1 = res.threeTest1
        this.microbeInspectionReportForm.threeTest2 = res.threeTest2
        this.microbeInspectionReportForm.threeTest21 = res.threeTest21
        this.microbeInspectionReportForm.threeTest22 = res.threeTest22
        this.microbeInspectionReportForm.threeTest221 = res.threeTest221
        this.microbeInspectionReportForm.threeTest222 = res.threeTest222
        this.microbeInspectionReportForm.threeTest223 = res.threeTest223
        this.microbeInspectionReportForm.threeFinalResult = res.threeFinalResult
        this.microbeInspectionReportForm.fourStandard = res.fourStandard
        this.microbeInspectionReportForm.fourMedium = res.fourMedium
        this.microbeInspectionReportForm.fourTemperature = res.fourTemperature
        this.microbeInspectionReportForm.fourTime = res.fourTime
        this.microbeInspectionReportForm.fourResult = res.fourResult
        this.microbeInspectionReportForm.fourTest1 = res.fourTest1
        this.microbeInspectionReportForm.fourTest11 = res.fourTest11
        this.microbeInspectionReportForm.fourTest12 = res.fourTest12
        this.microbeInspectionReportForm.fourFinalResult = res.fourFinalResult
        this.microbeInspectionReportForm.fiveStandard = res.fiveStandard
        this.microbeInspectionReportForm.fiveMedium = res.fiveMedium
        this.microbeInspectionReportForm.fiveTemperature = res.fiveTemperature
        this.microbeInspectionReportForm.fiveTime = res.fiveTime
        this.microbeInspectionReportForm.fiveResult = res.fiveResult
        this.microbeInspectionReportForm.fiveTest1 = res.fiveTest1
        this.microbeInspectionReportForm.fiveTest11 = res.fiveTest11
        this.microbeInspectionReportForm.fiveTest12 = res.fiveTest12
        this.microbeInspectionReportForm.fiveFinalResult = res.fiveFinalResult
        this.microbeInspectionReportForm.sixStandard = res.sixStandard
        this.microbeInspectionReportForm.sixMedium = res.sixMedium
        this.microbeInspectionReportForm.sixTemperature = res.sixTemperature
        this.microbeInspectionReportForm.sixTime = res.sixTime
        this.microbeInspectionReportForm.sixFlat1 = res.sixFlat1
        this.microbeInspectionReportForm.sixFlat2 = res.sixFlat2
        this.microbeInspectionReportForm.sixFlat3 = res.sixFlat3
        this.microbeInspectionReportForm.sixFlat4 = res.sixFlat4
        this.microbeInspectionReportForm.sixFlat5 = res.sixFlat5
        this.microbeInspectionReportForm.sixColonyTotal = res.sixColonyTotal
        this.microbeInspectionReportForm.remark = res.remark
        this.microbeInspectionReportForm.reportConclusion = res.reportConclusion
        this.microbeInspectionReportForm.reviewResult = res.reviewResult
        this.microbeInspectionReportForm.approvalResult = res.approvalResult
        this.microbeInspectionReportForm.status = res.status
        this.microbeInspectionReportForm.taskId = res.taskId
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectMicrobeInspectionReportList(this.searchForm).then(res => {
        this.microbeInspectionReportPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectMicrobeInspectionReportList(this.searchForm).then(res => {
        this.microbeInspectionReportPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectMicrobeInspectionReportList(this.searchForm).then(res => {
        this.microbeInspectionReportPage = res
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['MICROBE_REVIEW']) && row.status === 1) {
        return 'review'
      } else if (this.checkPermission(['MICROBE_APPROVAL']) && row.status === 2) {
        return 'review'
      }
      return ''
    },
    getPdf () {
      let loading = ''
      let dom = document.getElementById('pdfDom')
      html2PDF(dom, {
        jsPDF: {
          unit: 'px',
          format: 'a4'
        },
        html2canvas: {
          imageTimeout: 15000,
          logging: true,
          useCORS: true
        },
        imageType: 'image/jpeg',
        imageQuality: 1,
        margin: {
          top: 20,
          right: 20,
          bottom: 0,
          left: 0
        },
        output: `微生物检验报告${this.microbeInspectionReportForm.formNo}.pdf`,
        init: function () {
          loading = Loading.service({
            lock: true,
            text: 'pdf加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
        },
        success: function (pdf) {
          pdf.save(this.output)
          loading.close()
        }
      })
    }
  }
}
</script>

<style>
</style>
