import axios from '@/common/axios'
import qs from 'qs'

export function addMicrobeInspectionReport (data) {
  return axios({
    method: 'post',
    url: '/quality/microbe/add',
    data: qs.stringify(data)
  })
}

export function deleteMicrobeInspectionReport (id) {
  return axios({
    method: 'delete',
    url: '/quality/microbe/delete/' + id
  })
}

export function updateMicrobeInspectionReport (data) {
  return axios({
    method: 'put',
    url: '/quality/microbe/update',
    data: qs.stringify(data)
  })
}

export function selectMicrobeInspectionReportInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/microbe/info/' + id
  })
}

export function selectMicrobeInspectionReportList (query) {
  return axios({
    method: 'get',
    url: '/quality/microbe/list',
    params: query
  })
}

export function reviewMicrobeInspectionReport (data) {
  return axios({
    method: 'put',
    url: '/quality/microbe/updateReview',
    data: qs.stringify(data)
  })
}

export function approvalMicrobeInspectionReport (data) {
  return axios({
    method: 'put',
    url: '/quality/microbe/updateApprove',
    data: qs.stringify(data)
  })
}
